<template>
  <v-snackbar
    v-model="notifyStore.show"
    :timeout="notifyStore.timeout"
    :color="notifyStore.color"
    :class="notifyStore.position"
  >
    <div class="snackbar-content">
      <v-icon v-if="notifyStore.icon" class="icon">{{ notifyStore.icon }}</v-icon>
      <div>
        <div>{{ notifyStore.message }}</div>
        <div v-if="notifyStore.caption" class="caption">{{ notifyStore.caption }}</div>
      </div>
    </div>
    <template #actions>
      <v-btn
        v-for="action in notifyStore.actions"
        :key="action.label"
        :color="action.color"
        @click="action.handler"
      >
        <v-icon v-if="action.icon" class="mr-2">{{ action.icon }}</v-icon>
        {{ action.label }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup lang="ts">
import { useNotifyStore } from '../../store'

const notifyStore = useNotifyStore()
</script>

<script lang="ts">
export default {
  name: 'NotifyComponent',
}
</script>

<style scoped>
.caption {
  font-size: 0.8em;
}

.v-snackbar {
  z-index: 9000 !important;
}

.snackbar-content {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 8px;
}
</style>

<template>
  <v-app>
    <q-layout
      class="window-height window-width justify-center items-center"
      style="overflow-x: hidden"
      view="hHh lPr fFf"
    >
      <q-page-container id="app" class="window-height window-width justify-center items-center">
        <router-view name="header"></router-view>
        <v-main height="100%">
          <router-view id="view"></router-view>
        </v-main>
        <Notify />
      </q-page-container>
    </q-layout>
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Notify from './components/notify/Notify.vue'

export default defineComponent({
  name: 'App',
  components: {
    Notify,
  },
})
</script>

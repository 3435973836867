import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

import { pt } from 'vuetify/locale'

const colors = {
  primary: '#C31C4A',
  secondary: '#EAC50B',
  accent: '#F6CF0A',
  dark: '#1D1D1D',
  positive: '#3AA803',
  negative: '#DC3545',
  info: '#17A2B8',
  warning: '#FFC107',
  grey300: '#E0E0E0',
  grey500: '#9E9E9E',
  grey600: '#757575',
  grey700: '#616161',
  grey800: '#424242',
  background: '#f5f5f5',
}

export default createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  locale: {
    locale: 'pt',
    messages: { pt },
  },
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        colors,
      },
      dark: {
        colors: {
          ...colors,
          background: colors.dark,
        },
      },
    },
  },
})

import { defineStore } from 'pinia'
import { ref } from 'vue'
import { NotificationAction, NotificationDTO } from '../interfaces'

export const useNotifyStore = defineStore('notify', () => {
  const show = ref(false)
  const icon = ref('')
  const message = ref('')
  const caption = ref('')
  const position = ref('bottom')
  const color = ref('success')
  const timeout = ref(3000)
  const actions = ref<NotificationAction[]>([])

  const notify = (notification: NotificationDTO) => {
    message.value = notification.message
    icon.value = notification.icon
    caption.value = notification.caption || ''
    position.value = notification.position || 'bottom'
    color.value = notification.color || 'success'
    timeout.value = notification.timeout || 3000
    actions.value = notification.actions || []
    show.value = true
  }

  const close = () => {
    show.value = false
  }

  return {
    show,
    message,
    icon,
    caption,
    position,
    color,
    timeout,
    actions,
    notify,
    close,
  }
})

import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import { useAuthStore } from '../store'
import { Auth } from 'aws-amplify'
import { api } from '../api'

const isJwtExpired = (token: string): boolean => {
  try {
    const base64Url = token?.split('.')[1]
    if (!base64Url) {
      console.error('Invalid JWT token format.')
      return true
    }

    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )

    const decodedToken = JSON.parse(jsonPayload)

    const exp = decodedToken.exp

    if (!exp) {
      console.error("No 'exp' field in token")
      return true
    }

    const currentTime = Math.floor(Date.now() / 1000)

    return currentTime > exp
  } catch (error) {
    console.error('Error decoding token:', error)
    return true
  }
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

const getToken = async () => {
  try {
    const session = await Auth.currentSession()
    return session.getIdToken().getJwtToken()
  } catch (error) {
    return ''
  }
}

const getUserRoutes = async () => {
  const { userDept } = useAuthStore()

  if (!userDept) return []

  try {
    const { data } = await api.get(`/profile-user-api?id=${userDept}`)

    const [department] = data
    return department?.routes || []
  } catch (error) {
    console.error('Error fetching user routes:', error)
    return []
  }
}

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  const token = await getToken()

  if (requiresAuth) {
    if (!isJwtExpired(token)) {
      if (from.name === 'login') {
        next()
        return
      }

      const userRoutes = await getUserRoutes()
      if (userRoutes.includes(to.name)) {
        next()
      } else {
        next('/home')
      }
    } else {
      next('/login')
    }
  } else {
    next()
  }
})

export default router
